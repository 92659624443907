import {Form, useFieldAnswer} from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import {registerCoreBlocks} from "@quillforms/react-renderer-utils";
import "./styles.css";

registerCoreBlocks();
const App = () => {
    const changeOrderQuestion = useFieldAnswer("change-order-question");
    const purchaseTypeQuestion = useFieldAnswer("purchase-type-question");
    const purchaseSizeQuestion = useFieldAnswer("purchase-size-question");
    const cityCouncilApprovalQuestion = useFieldAnswer("city-council-approval-question");
    const notesForAllPurchasesImage = "<img alt='Notes for All Purchases' src='/images/notes.png'/>";

    return (
        <div style={{width: "100%", height: "100vh"}}>
            <Form
                formId="1"
                formObj={{
                    blocks: [
                        {
                            id: "welcome-screen",
                            name: "welcome-screen",
                            attributes: {
                                buttonText: "Let's start!",
                                label: "Welcome to City of Concord Checklist",
                                attachment: {
                                    type: "image",
                                    url: "/images/logo.png"
                                }
                            }
                        },
                        {
                            id: "change-order-question",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "yes",
                                        label: "YES"
                                    },
                                    {
                                        value: "no",
                                        label: "NO"
                                    }
                                ],
                                verticalAlign: false,
                                required: true,
                                label: "Is this a change order/increase request?",
                            }
                        },
                        ...(changeOrderQuestion?.includes("yes")
                            ? [
                                {
                                    name: "statement",
                                    id: "signature-authorization",
                                    attributes: {
                                        customHTML: notesForAllPurchasesImage,
                                        label: "See <strong>'Signature Authorization section in 'Notes for All Purchases'</strong> on the next screen in addition to the following:\n\n" +
                                            "<small>City Council approval is not required for execution of any modification:\n\n" +
                                            "-If seeking approval will cause a delay that will either: (a) subject the City to liability for damages incurred by a Contractor, (b) jeopardize the public health, welfare or safety, or (c) otherwise result in potential detriment to the City.\n\n" +
                                            "-If the original contract states the terms and conditions for modifications in subsequent years and was approved by City Council accordingly.</small>",
                                        buttonText: "Continue",
                                        quotationMarks: true
                                    }
                                }
                            ]
                            : []),


                        ...(changeOrderQuestion?.includes("no")
                            ? [
                                {
                                    name: "multiple-choice",
                                    id: "purchase-type-question",
                                    attributes: {
                                        choices: [
                                            {
                                                value: "supplies-materials",
                                                label: "Supplies / Materials"
                                            },
                                            {
                                                value: "equipment",
                                                label: "Equipment"
                                            },
                                            {
                                                value: "services",
                                                label: "Services"
                                            },
                                            {
                                                value: "public-projects",
                                                label: "Public Projects",
                                            },
                                            {
                                                value: "professional-services",
                                                label: "Professional Services"
                                            },
                                            {
                                                value: "special-projects",
                                                label: "Special Projects"
                                            },
                                        ],
                                        verticalAlign: false,
                                        required: true,
                                        label: "What type of purchase is this?",
                                    }
                                }
                            ]
                            : []),

                        ...(changeOrderQuestion?.includes("no") && purchaseTypeQuestion?.includes("special-projects")
                            ? [
                                {
                                    name: "statement",
                                    id: "special-projects-statement",
                                    attributes: {
                                        customHTML: notesForAllPurchasesImage,
                                        label: "Exempt from informal/formal bidding. (Use Justification Form)",
                                    }
                                }
                            ]
                            : []),
                        ...(changeOrderQuestion?.includes("no") && !purchaseTypeQuestion?.includes("special-projects")
                            ? [
                                {
                                    name: "multiple-choice",
                                    id: "purchase-size-question",
                                    attributes: {
                                        choices: [
                                            {
                                                value: "under-2.5k",
                                                label: "Under $2,500"
                                            },
                                            {
                                                value: "2.5k-10k",
                                                label: "$2,501 - $10,000"
                                            },
                                            {
                                                value: "10k-50k",
                                                label: "$10,001 - $50,000"
                                            },
                                            ...(purchaseTypeQuestion?.includes("public-projects") ? [
                                                {
                                                    value: "over-50k",
                                                    label: "$50,001 - $125,000"
                                                },
                                                {
                                                    value: "over-125k",
                                                    label: "Over $125,000"
                                                }
                                            ] : [
                                                {
                                                    value: "over-50k",
                                                    label: "Over $50,000"
                                                }
                                            ])
                                        ],
                                        verticalAlign: false,
                                        required: true,
                                        label: "How large is this purchase?"
                                    }
                                }
                            ] : []),


                        ...(changeOrderQuestion?.includes("no") && purchaseTypeQuestion?.includes("public-projects") && purchaseSizeQuestion?.includes("over-50k")
                            ? [
                                {
                                    name: "multiple-choice",
                                    id: "city-council-approval-question",
                                    attributes: {
                                        choices: [
                                            {
                                                value: "yes",
                                                label: "YES"
                                            },
                                            {
                                                value: "no",
                                                label: "NO"
                                            }
                                        ],
                                        verticalAlign: false,
                                        required: true,
                                        label: "Is City Council Approval Required?",
                                    }
                                }
                            ]
                            : []),

                        ...(changeOrderQuestion?.includes("no") && !purchaseTypeQuestion?.includes("special-projects") && purchaseSizeQuestion?.includes("under-2.5k")
                            ? [
                                {
                                    name: "statement",
                                    id: "direct-payment-statement",
                                    attributes: {
                                        label: "<h1><u>Direct payment</u></h1>",
                                        customHTML: "<strong><u>FORMS/DOCUMENTS*:</u></strong><br><small>-Submit <u><i>Request for PaymentForm</i></u>.</small>" +
                                            "<br><small>-Attach with invoice and supporting documents such asa signed invoice copy.</small>" +
                                            "<br><strong><u>SUBMIT TO:</u></strong>" +
                                            "<br><small>Accounts Payable, Finance</small>" +
                                            "<br><strong><u>NOTE:</u></strong>" +
                                            "<br><small><i>If requested, Purchasing may process Purchase Order (PO) but a requisition must be entered online.</i></small>" +
                                            notesForAllPurchasesImage,
                                    }
                                }
                            ]
                            : []),
                        ...(changeOrderQuestion?.includes("no") && !purchaseTypeQuestion?.includes("special-projects") && purchaseSizeQuestion?.includes("2.5k-10k")
                            ? [
                                {
                                    name: "statement",
                                    id: "non-competitive-bid-statement",
                                    attributes: {
                                        label: "<h1><u>Non-Competitive Bid (1 Quote)</u></h1>",
                                        customHTML: "<strong><u>FORMS/DOCUMENTS*:</u></strong>" +
                                            "<br><small>-Submit <u><i>Requisition online</i></u> in Lawson system for Purchase Order.</small>" +
                                            "<br><small>-Attach with supporting documents such as <u><i>the quote</i></u>/proposal copy.</small>" +
                                            "<br><strong><u>SUBMIT TO:</u></strong>" +
                                            "<br><small>Purchasing Department who will provide a PO copy for department reference</small>" +
                                            "<br><strong>PAYMENT FOR A/P:</strong>" +
                                            "<br><small> Reference PO# on all invoices and submit to A/l to process payment</small>" +
                                            notesForAllPurchasesImage,
                                    }
                                }
                            ]
                            : []),
                        ...(
                            (changeOrderQuestion?.includes("no") && !purchaseTypeQuestion?.includes("special-projects") && purchaseSizeQuestion?.includes("10k-50k"))
                            || (changeOrderQuestion?.includes("no") && purchaseTypeQuestion?.includes("public-projects") && purchaseSizeQuestion?.includes("over-50k") && cityCouncilApprovalQuestion?.includes("no"))
                                ? [
                                    {
                                        name: "statement",
                                        id: "informal-bid-statement",
                                        attributes: {
                                            label: "<h1><u>Informal Bids (3 Quotes)</u></h1>",
                                            customHTML: "<strong><u>FORMS/DOCUMENTS*:</u></strong>" +
                                                "<br><small>-Obtain quotes from at least 3 vendors. (one from Concord if available).</small>" +
                                                "<br><small>-Submit <u><i><strong>Informal Bid/Quote Summary Form</strong></i></u></small>" +
                                                "<br><small>-Attach with supporting documents such as the quote/proposal copy, insurance requirements, etc.</small>" +
                                                "<br><small>-Reference/attach any Contract #, Bid #, Council approval information, etc.</small>" +
                                                "<br><small>-Submit Requisition online in Lawson system for Purchase Order.</small>" +
                                                "<br><strong><u>SUBMIT TO:</u></strong>" +
                                                "<br><small>Purchasing Department</small>" +
                                                "<br><strong><u>NOTE:</u></strong>" +
                                                "<br><small><i>Utilize <u><strong>Request for Quote (RFQ)</strong></u> form or any of the approved bid templates to solicit bids if desired.</i></small>" +
                                                notesForAllPurchasesImage,
                                        }
                                    }
                                ]
                                : []),
                        ...(
                            (changeOrderQuestion?.includes("no") && !purchaseTypeQuestion?.includes("special-projects") && !purchaseTypeQuestion?.includes("public-projects") && purchaseSizeQuestion?.includes("over-50k"))
                            || (changeOrderQuestion?.includes("no") && purchaseTypeQuestion?.includes("public-projects") && purchaseSizeQuestion?.includes("over-50k") && cityCouncilApprovalQuestion?.includes("yes"))
                            || (changeOrderQuestion?.includes("no") && purchaseTypeQuestion?.includes("public-projects") && purchaseSizeQuestion?.includes("over-125k"))
                                ? [
                                    {
                                        name: "statement",
                                        id: "formal-bid-statement",
                                        attributes: {
                                            label: "<h1><u>Formal Bids (Published Notice)</u></h1>",
                                            customHTML: "<strong><u>FORMS/DOCUMENTS*:</u></strong>" +
                                                "<br><small>-Submit specifications & special provisions in Word. (Use <u><i><strong>In-House Cost Estimate Form</strong></i></u>) which includes cost estimate, timeline/ schedule of events.</small>" +
                                                "<br><small>-Submit <u><i><strong>Solicitation List Form</strong></i></u></small>" +
                                                "<br><small>-Attach any supporting documents as applicable. (Purchasing will work with depts to finalize bid documents)</small>" +
                                                "<br><br><small><u>Once Formal Bidding Process is complete:</u></small>" +
                                                "<br><small>-(For RFBs) Purchasing will provide Bid Results. etc.</small>" +
                                                "<br><small>-(For RFPs) Purchasing will provide 'Evaluation Process' information. Select Vendor upon evaluation.</small>" +
                                                "<br><small>-Prepare Council Report/Council Award Date</small>" +
                                                "<br><small>-Prepare contract if applicable.</small>" +
                                                "<br><small>-Submit <u><i>Requisition online</i></u> in Lawson system for Purchase Order.</small>" +
                                                "<br><br><strong><u>SUBMIT TO:</u></strong>" +
                                                "<br><small>Purchasing Department</small>" +
                                                notesForAllPurchasesImage,
                                        }
                                    }
                                ]
                                : []),
                    ],
                    settings: {
                        animationDirection: "vertical",
                        disableWheelSwiping: false,
                        disableNavigationArrows: false,
                        disableProgressBar: false
                    },
                    "messages": {
                        "label.button.ok": "OK",
                        "label.hintText.enter": "press <strong>Enter \u21b5</strong>",
                        "label.hintText.multipleSelection": "Choose as many as you like",
                        "block.dropdown.placeholder": "Type or select an option",
                        "block.dropdown.noSuggestions": "No Suggestions!",
                        "block.shortText.placeholder": "Type your answer here",
                        "block.longText.placeholder": "Type your answer here",
                        "block.longText.hint": "<strong>Shift \u21e7 + Enter \u21b5</strong> to make a line break",
                        "block.longText.touchHint": "<strong> Enter \u21b5</strong> to make a line break",
                        "block.number.placeholder": "Type your answer here",
                        "block.email.placeholder": "Type your email here",
                        "block.defaultThankYouScreen.label": "Thank you for using the checklist! " +
                            "<br><a href='/' class='renderer-core-button thankyou-button'>Restart Checklist!</a>",
                        "label.hintText.key": "Key",
                        "label.yes.default": "Yes",
                        "label.no.default": "No",
                        "label.progress.percent": "{{progress:percent}}% completed",
                        "label.errorAlert.required": "This field is required!",
                        "label.errorAlert.date": "Invalid date!",
                        "label.errorAlert.number": "Numbers only!",
                        "label.errorAlert.selectionRequired": "Please make at least one selection!",
                        "label.errorAlert.email": "Invalid email!",
                        "label.errorAlert.url": "Invalid url!",
                        "label.errorAlert.range": "Please enter a number between {{attribute:min}} and {{attribute:max}}",
                        "label.errorAlert.minNum": "Please enter a number greater than {{attribute:min}}",
                        "label.errorAlert.maxNum": "Please enter a number lower than {{attribute:max}}",
                        "label.errorAlert.maxCharacters": "Maximum characters reached!",
                        "label.errorAlert.minCharacters": "Please insert more than {{attribute:minCharacters}} characters!",
                        "label.submitBtn": "Finish",
                        "label.errorAlert.noConnection": "Can't connect to the server right now!",
                        "label.errorAlert.serverError": "Server error!"
                    },
                    theme: {
                        font: "Roboto",
                        buttonsBgColor: "#005eb8",
                        logo: {
                            src: ""
                        },
                        questionsColor: "#000",
                        answersColor: "#005eb8",
                        buttonsFontColor: "#fff",
                        buttonsBorderRadius: 25,
                        errorsFontColor: "#fff",
                        errorsBgColor: "#f00",
                        progressBarFillColor: "#000",
                        progressBarBgColor: "#ccc"
                    }
                }}
                onSubmit={(data, {completeForm, setIsSubmitting}) => {
                    setTimeout(() => {
                        setIsSubmitting(false);
                        completeForm();
                    }, 500);
                }}
            />
        </div>
    );
};

export default App;
